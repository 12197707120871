import { apiRequest } from "src/async/apiUtils";
import { getCookies } from "src/helpers";

const verifySSOAccessToken = async (tokenField) => {
  try {
    const cookies = getCookies();
    const accessToken = cookies[tokenField];

    if (!accessToken) return { status: 400 };

    const res = await apiRequest("post", "/portal/verify-auth0-sso-token", {
      body: {
        [tokenField]: accessToken,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });

    if (res.status === 200) {
      // Auto login the user
      const { access_token, refresh_token, email } = res.data;

      localStorage.setItem("email", email);
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("isSubDomain", res?.data?.isSubDomain);
      window.location.reload();

      return { status: 200 };
    }

    return { status: res.status };
  } catch (err) {
    return { status: 401 };
  }
};

const auth = { verifySSOAccessToken };

export default auth;
