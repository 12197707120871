import React, { useEffect, useRef, useState } from "react";
import { classNames } from "src/helpers/classNames";
import SignatureCanvas from "react-signature-canvas";
import Button from "./Button";
import Image from "../elements/Image";

const SignatureArea = ({ label, name, disabled = false, value = "", error = false, type = "text", inputClassNames, labelClassNames = "", onChange = () => {}, inline = false, wrapperClass, penColor = "black", fileName = "signature" }) => {
  const signatureRef = useRef(null);
  const containerRef = useRef(null);

  const [showButton, setShowButton] = useState(false);

  const [canvasWidth, setCanvasWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setCanvasWidth(containerRef.current.clientWidth);
    }

    // Update on window resize
    const handleResize = () => {
      if (containerRef.current) {
        setCanvasWidth(containerRef.current.clientWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showButton]);

  const handleClear = () => {
    signatureRef.current.clear();
    onChange(null);
    setShowButton(false);
  };

  const handleSave = () => {
    signatureRef.current.getTrimmedCanvas().toBlob((blob) => {
      if (blob) {
        const file = new File([blob], `${fileName}.png`, { type: "image/png" });
        onChange(file);
        signatureRef.current.clear();
        setShowButton(false);
      }
    });
  };

  const labelClasses = classNames(labelClassNames, "flex text-sm font-medium mb-1", !inline && disabled && "cursor-not-allowed", inline && disabled && "bg-transparent");
  const inputClasses = classNames(
    `min-h-[300px] w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor`,
    error && "border-red-300",
    disabled ? "text-gray-400" : "text-gray-700",
    !inline && disabled && "cursor-not-allowed",
    inline && disabled && "bg-transparent",
  );

  return (
    <div className={classNames("w-full", inline ? "relative h-full space-y-2 overflow-hidden rounded-md border px-0 pt-2 focus-within:border-highlightColor" : "relative h-full ", inline && disabled ? "border-gray-100 bg-gray-50" : "border-gray-300 shadow-sm", wrapperClass)}>
      <label
        htmlFor={name}
        className={inline ? classNames("m-0 flex pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3", labelClasses) : labelClasses}>
        {label} {error && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
      </label>
      <div
        ref={containerRef}
        className="w-[100%]">
        <SignatureCanvas
          ref={signatureRef}
          penColor={penColor}
          onBegin={() => setShowButton(true)}
          canvasProps={{
            width: canvasWidth,
            height: 300,
            className: classNames(inputClassNames ? inputClassNames : inputClasses, type === "code" ? "font-mono" : "", inline ? "h-28 rounded-none border-none bg-transparent py-0 shadow-none focus:border-none focus:ring-0" : ""),
          }}
        />
      </div>
      {(value || showButton) && (
        <div className="flex w-full items-center justify-start gap-3 px-3 py-3">
          {value && (
            <div className="relative aspect-[3/1] w-56 overflow-hidden ">
              <Image
                src={value && URL.createObjectURL(value)}
                wrapperClasses={"w-full h-full"}
                hideImageOnError
              />
            </div>
          )}

          {showButton && (
            <div className="ml-auto flex items-center justify-end gap-3">
              <Button
                buttonClasses={"border border-gray-300 bg-white"}
                buttonLabelClasses={"text-gray-700"}
                buttonFunction={handleClear}
                buttonLabel={"Clear"}
              />
              <Button
                buttonClasses={"bg-highlightColor"}
                buttonFunction={handleSave}
                buttonLabel={"Save"}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SignatureArea;
