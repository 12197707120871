import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { AuthContext } from "src/context/auth";
import { clearCookies } from "src/helpers";

const Authorization = () => {
  const { token } = useParams();
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const getRedirectLink = async () => {
    try {
      const { data: resData } = await apiRequest("GET", `/portal/users/workspaces/page`);

      if (resData.status === 200) {
        toast.success("Successfully logged in.");
        navigate(resData.link);
      } else {
        toast.error("No workspace found.");
        navigate("/permission-denied");
      }
    } catch (err) {
      toast.error(err.message);
      navigate("/permission-denied");
    }
  };

  const tokenVerify = async () => {
    try {
      localStorage.removeItem("email");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isSubDomain");
      clearCookies();
      setUser(null);

      const { data } = await apiRequest("get", `/portal/switch-to-user-token/verify`, { body: { token } });
      localStorage.setItem("email", data?.data.email);
      localStorage.setItem("accessToken", data?.access_token);
      localStorage.setItem("refreshToken", data?.refresh_token);
      localStorage.setItem("isSubDomain", data?.isSubDomain);
      await getRedirectLink();
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) tokenVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Preloader text="Authentication..." />
    </div>
  );
};

export default Authorization;
